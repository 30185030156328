// Import all of Bootstrap's CSS
@import "~bootstrap/scss/bootstrap";

.card-img-top {
  width: 100%;
  height: 25vw;
  object-fit: cover;
}

// Style from medium breakpoint and down
// `md` applies to small devices (landscape phones, less than 768px)
@include media-breakpoint-down(md) {
  .card-img-top {
    width: 100%;
    height: 75vw;
    object-fit: cover;
  }
}
